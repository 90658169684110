<template lang="pug">
BasePane
  Accordion.mt-3(fix)
    OmColorInput(
      :label="$t('backgroundColor')"
      property="globalStyle.teaserCloseButton.$device.background.color"
      typeOfComponent="global"
      layout="col"
      editMobile
      dsAllowed
    )
    OmColorInput(
      :label="$t('xColor')"
      property="globalStyle.teaserCloseButton.$device.color"
      typeOfComponent="global"
      layout="col"
      editMobile
      dsAllowed
    )
    FontFamily(v-model="closeFontFamily" manage layout="row")
    RangeInput(:label="$t('size')" v-model="size" editMobile :min="8" :max="70" :step="1")
    FontStyle(
      :label="$t('style')"
      :textWeight.sync="weight"
      :fontItalic.sync="italic"
      :textDecoration.sync="decoration"
    )
    RangeInput(:label="$t('borderRadius')" editMobile v-model="radius" :min="0" :max="50")
    Margin(customProperty="globalStyle.teaserCloseButton.$device.margin")
</template>
<script>
  import { mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import PaneLayout from '../../PaneLayout.vue';
  import FontFamily from '../../Controls/FontFamily.vue';
  import FontStyle from '../../FontStyle/FontStyle.vue';
  import Dropdown from '../../Dropdown/Dropdown.vue';
  import Margin from '../../Controls/InputGroup/Margin.vue';

  export default {
    components: {
      Accordion,
      Heading,
      Align,
      RangeInput,
      BasePane,
      PaneLayout,
      FontFamily,
      FontStyle,
      Dropdown,
      Margin,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
      canUseNested: true,
    }),
    computed: {
      ...mapState(['mobilePreview']),
      closeFontFamily: {
        get() {
          return this.getNested('globalStyle.teaserCloseButton.$device.fontFamily');
        },
        set(value) {
          this.setNested('globalStyle.teaserCloseButton.$device.fontFamily', value);
        },
      },
      size: {
        get() {
          const mobileValue = this.getNested('globalStyle.teaserCloseButton.mobile.fontSize');
          if (this.mobilePreview && mobileValue == null) {
            return Number(this.getNested('globalStyle.teaserCloseButton.desktop.fontSize'));
          }

          return Number(this.getNested('globalStyle.teaserCloseButton.$device.fontSize'));
        },
        set(value) {
          this.setNested('globalStyle.teaserCloseButton.$device.fontSize', value);
        },
      },
      weight: {
        get() {
          return this.getValueOf('globalStyle.teaserCloseButton.textWeight');
        },
        set(value) {
          this.setValueOf('globalStyle.teaserCloseButton.textWeight', value);
        },
      },
      italic: {
        get() {
          return this.getValueOf('globalStyle.teaserCloseButton.fontItalic');
        },
        set(value) {
          this.setValueOf('globalStyle.teaserCloseButton.fontItalic', value);
        },
      },
      decoration: {
        get() {
          return this.getValueOf('globalStyle.teaserCloseButton.textDecoration');
        },
        set(value) {
          this.setValueOf('globalStyle.teaserCloseButton.textDecoration', value);
        },
      },
      radius: {
        get() {
          const mobileValue = this.getNested('globalStyle.teaserCloseButton.mobile.borderRadius');
          if (this.mobilePreview && mobileValue == null) {
            return Number(this.getNested('globalStyle.teaserCloseButton.desktop.borderRadius'));
          }
          return Number(this.getNested('globalStyle.teaserCloseButton.$device.borderRadius'));
        },
        set(value) {
          this.setNested('globalStyle.teaserCloseButton.$device.borderRadius', value);
        },
      },
    },
    methods: {
      getNested(property) {
        if (this.mobilePreview) {
          return this.getValueOf(property.replace('$device', 'mobile'));
        }

        return this.getValueOf(property.replace('$device', 'desktop'));
      },
      setNested(property, value) {
        if (this.mobilePreview) {
          this.setValueOf(property.replace('$device', 'mobile'), value);
          return;
        }

        this.setValueOf(property.replace('$device', 'desktop'), value);
      },
    },
  };
</script>
